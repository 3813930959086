<template>
  <div>
    <el-form
      ref="generateFormRef"
      :model="generateForm"
      label-position="left"
      inline
    >
      <el-form-item label="Style">
        <div class="disabled-input">
          {{ $route.query.styleName }}
        </div>

      </el-form-item>
      <el-form-item label="供应商">
        <Select
          v-model="generateForm.vendorId"
          :disabled="loading"
          :select-options="vendorOptions"
          :clearable="false"
          :configuration="optionsConfig"
          @change="handleMqDetail"
        />
      </el-form-item>
      <el-form-item label="单款MOQ">
        <el-input v-model="generateForm.styleMoq" disabled placeholder="请输入" />
      </el-form-item>

      <el-form-item label="单款待凑单量" label-width="100px">
        <div class="disabled-input">
          {{ handleWaitedAmount() }}
        </div>
      </el-form-item>
      <el-form-item label="备注及说明" label-width="100px">
        <el-input
          v-model="generateForm.remark"
          type="textarea"
          :rows="1"
          disabled
        />
      </el-form-item>
    </el-form>
    <el-button
      type="text"
      @click="handleAddOn('AUTO')"
    >自动凑单</el-button>
    <el-button
      type="text"
      @click="handleAddOn('CUSTOM_NUMBER')"
    >指定数量凑单</el-button>
    <el-button type="text" @click="handleUpRound">向上取整箱</el-button>

    <vxe-table
      ref="xTable"
      v-loading="loading"
      border
      show-overflow
      :data="tableData"
      align="center"
      :cell-style="cellStyle"
      class="mt-3"
      show-footer
      show-footer-overflow
      :footer-method="(e) => footerMethod(e, 0)"
      max-height="600px"
      :scroll-x="{ enabled: true, gt: 20 }"
      :scroll-y="{ enabled: true, gt: 20 }"
      @checkbox-change="({records})=>multipleSelection = records"
      @checkbox-all="({records})=>multipleSelection = records"
    >
      <vxe-column type="checkbox" width="50" />
      <vxe-column type="seq" width="60" />
      <vxe-column field="colorName" width="180" title="Color" />
      <vxe-column field="cargoTypeList" min-width="120" title="装箱数">
        <template #default="{ row }">
          {{ row && row.cargoTypeList && row.cargoTypeList.join("/") }}
        </template>
      </vxe-column>
      <vxe-column
        field="planPurchaseNumber"
        min-width="120"
        title="计划采购数量"
      />
      <vxe-column field="overboughtNumber" min-width="120" title="超买数量" />
      <vxe-column
        field="totalPlanPurchaseNumber"
        min-width="120"
        title="总采购数量"
      />
      <vxe-column field="colorMoq" min-width="120" title="单色MOQ" />
      <vxe-column field="moqTobeAdded" min-width="120" title="单色待凑单量" />
      <vxe-column
        v-for="(e,idx) in monthList"
        :key="idx"
        :field="e"
        width="120"
        :title="e"
      >
        <template #default="{ row }">
          {{ row[e]||0 }}
        </template>
      </vxe-column>
      <vxe-column title="操作" width="120" fixed="right" field="operate">
        <template #default="{ row, rowIndex }">
          <el-button
            v-if="showCancelBtn(row,rowIndex)"
            type="text"
            :loading="submitLoading"
            @click="removeEvent(row, rowIndex)"
          >取消下单</el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <div style="text-align: center; margin-top: 30px">
      <el-button @click="goBack"> 返回 </el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleNext"> 下一步</el-button>
    </div>
    <AddTo
      v-model="dialogVisible"
      :basic-info="basicInfo"
      :month-list="monthList"
      :detail-list="multipleSelection"
      :dialog-type="dialogType"
      @success="handleOverBought(multipleSelection,false)"
    />
  </div>
</template>
<script>
import NP from 'number-precision'
import VXETable from 'vxe-table'
import Select from '@/components/Selection'
import AddTo from './AddTo'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { mapGetters } from 'vuex'
import XEUtils from 'xe-utils'
import { pick } from 'lodash'

import { moqConfirm, styleSearch, colorSearch, poDetailConfirmColor, vendorListByStyles } from '@/api/purchasebussiness/purchaseplan.js'
export default {
  components: {
    Select, AddTo
  },
  mixins: [commodityInfoDict],
  props: {
    styleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      basicInfo: {},
      dialogVisible: false,
      multipleSelection: [],
      optionsConfig: { key: 'vendorId', label: 'vendorName', value: 'vendorId' },
      loading: false,
      vendorOptions: [],
      submitLoading: false,
      rules: {
        resource: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      tableData: [],
      monthList: [],
      generateForm: {
        vendorId: '',
        remark: '',
        styleMoq: '',
        waitedAmount: ''
      },
      purchasePlanCodeList: [],
      colorMoqList: [],
      fromPath: '',
      dialogType: '',
      skuTableData: [],
      canUpRound: true
    }
  },
  computed: {
    ...mapGetters(['addOverboughtDataObject', 'OverboughtSkuTableData', 'moqComfirmTableData', 'overboughtVendorData'])
  },
  watch: {
    purchasePlanCodeList(val) {
      if (this.fromPath === 'purchasplan') {
        this.handleMoqConfirm(true)
      } else {
        this.handleMoqConfirm(false)
      }
    }

  },
  mounted() {
    this._queryVendorList()
    const { fromPath, canUpRound } = this.$route.query
    this.fromPath = fromPath
    this.purchasePlanCodeList = this.$route.query.purchasePlanCodeList.split(',')
    if (canUpRound === false) {
      this.canUpRound = false
    }
  },

  methods: {
    cellStyle({ row, column }) {
      if (row.markColorList?.includes(column.property)) {
        return {
          backgroundColor: '#95F204',
          color: '#000000'
        }
      }
    },
    handleMarkColor() {
      this.tableData.map(row => {
        row.markColorList = []
        const { netDemandList = [], overboughtNumber, moqTobeAdded } = row
        const compareNum = overboughtNumber || moqTobeAdded
        if (compareNum === '/') return
        let sum = 0
        for (var i = 0; i < netDemandList?.length; i++) {
          const { yearMonthStr, netDemandQty } = netDemandList[i]
          sum = NP.plus(sum, netDemandQty)
          compareNum && netDemandQty && row.markColorList.push(yearMonthStr)
          if (sum >= compareNum) {
            break
          }
        }
      })
    },
    handleUpRound() {
      if (!this.generateForm.vendorId) return this.$message.warning('请选择供应商')
      if (!this.canUpRound) return this.$message.warning('已生成凑单建议，不允许向上取整箱数')
      this.skuTableData.map(v => {
        const { cargoType, overboughtNumber, planPurchaseNumber } = v
        const sum = NP.plus(overboughtNumber || 0, planPurchaseNumber)
        if (planPurchaseNumber > 0 && sum % cargoType !== 0) {
          const count = cargoType * Math.ceil(sum / cargoType)
          v.overboughtNumber = NP.minus(count, planPurchaseNumber)
        } else {
          v.overboughtNumber = v.overboughtNumber || 0
        }
      })
      this.$store.commit(
        'pages/SET_CURRENT_OVERBOUGHT_SKU_TABLEDATA',
        this.skuTableData
      )
      this.$message.success('向上取整箱成功')
      this.handleOverBought(this.tableData, true)
    },
    handleAddOn(overboughtOrderMethod) {
      this.dialogType = overboughtOrderMethod
      const { vendorId, styleMoq } = this.generateForm
      if (!vendorId) return this.$message.warning('请选择供应商')
      if (!this.multipleSelection.length) {
        return this.$message.warning('至少勾选一条数据')
      }
      if (overboughtOrderMethod === 'AUTO' && (this.multipleSelection.some((v) => !v.colorMoq) || !styleMoq)) {
        return this.$msgbox({
          title: '提示',
          message: `请核对单款MOQ、各颜色单色MOQ值`
        })
      }
      const selectColorNameList = this.multipleSelection.map(v => v.colorName)
      this.basicInfo = Object.assign({}, this.generateForm, { selectColorNameList, overboughtOrderMethod, purchasePlanCodeList: this.purchasePlanCodeList })
      this.dialogVisible = true
    },
    async _queryVendorList() {
      const { styleName } = this.$route.query
      const { datas } = await vendorListByStyles([styleName])
      this.vendorOptions = datas
    },
    footerMethod({ columns, data }, index,) {
      const sum = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if (columnIndex > 2 && column.property !== 'operate') {
            return XEUtils.sum(data, column.property)
          }

          return ''
        })
      ]
      return sum
    },
    showCancelBtn(row, rowIndex) {
      const list = this.tableData.filter(v => v.planPurchaseNumber || v.overboughtNumber)
      if (list.length === 1 && row.colorName === list[0].colorName) {
        return false
      }
      return (row.planPurchaseNumber || row.overboughtNumber)
    },
    async handleNext() {
      if (this.tableData.every(v => !v.totalPlanPurchaseNumber)) return this.$message.warning('采购总数量为0，无法操作')
      // 下一步前端校验
      const { vendorId, vendorName, waitedAmount, styleMoq } = this.generateForm
      if (!vendorId) return this.$message.warning('请选择供应商')
      if (this.tableData.some(v => v.moqTobeAdded && v.moqTobeAdded !== '/') || waitedAmount) {
        const isFullArr = this.tableData.filter(item => item.moqTobeAdded && item.moqTobeAdded !== '/')
        let messageHtml = ''
        if (waitedAmount) { messageHtml += `<div>(单款MOQ-单款待凑单量) / 单款MOQ = ${((styleMoq - waitedAmount) / styleMoq * 100).toFixed(2)}% ，未满足单款MOQ。</div>` }
        for (let i = 0; i < isFullArr.length; i++) {
          const { colorName, colorMoq, moqTobeAdded } = isFullArr[i]
          messageHtml += `<div>Color${i + 1} ${colorName} (单色MOQ-单色待凑单量) / 单色MOQ =${((Number(colorMoq) - Number(moqTobeAdded)) / Number(colorMoq) * 100).toFixed(2)} % ，未满足单色MOQ。</div>`
        }
        return this.$msgbox({
          message: messageHtml,
          type: 'warning',
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          this.handleValiadate(vendorId, vendorName)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } else {
        this.handleValiadate(vendorId, vendorName)
      }
    },
    async handleValiadate(vendorId, vendorName) {
      try {
        this.submitLoading = true

        // 调用接口进入下一步
        const nextParams = {}
        const confirmColorList = this.tableData.filter(v => v.overboughtNumber || v.planPurchaseNumber).map(v => v.colorName)
        this.$store.commit('pages/SET_OVERBOUGHT_VENDOR_DATA', { vendorId, vendorName, confirmColorList })
        const { styleName } = this.$route.query
        const list = this.OverboughtSkuTableData.filter(v => v.overboughtNumber)
        Object.assign(nextParams, this.overboughtVendorData, {
          purchasePlanCodeList: this.purchasePlanCodeList,
          skuChannelOverboughtList: list?.map(item => Object.assign(item, { styleName })),
          styleName,
          validateFlag: true
        })
        const { datas } = await poDetailConfirmColor(nextParams)
        this.$store.commit('pages/SET_OVERBOUGHT_SPLIT_SKU_DATA', datas)
        this.$emit('updateActiveName', 1)
      } finally {
        this.submitLoading = false
      }
    },
    calculateTableData(list, groupedData) {
      const colorNameMap = new Map()
      groupedData.forEach(item => {
        const { colorName, overboughtNumber } = item
        if (!colorNameMap.has(colorName)) {
          colorNameMap.set(colorName, 0)
        }
        colorNameMap.set(colorName, NP.plus(colorNameMap.get(colorName), overboughtNumber))
      })

      return list.map(v => ({
        ...v,
        overboughtNumber: colorNameMap.get(v.colorName) || 0
      }))
    },
    handleWaitedAmount() {
      let totalPlanPurchaseNumber = 0
      totalPlanPurchaseNumber = this.tableData.reduce((acc, v) => {
        return NP.plus(acc, v.totalPlanPurchaseNumber || 0)
      }, 0)
      if (!this.generateForm.styleMoq) {
        return `请输入`
      }
      const waitedAmount = NP.minus(this.generateForm.styleMoq, totalPlanPurchaseNumber)
      this.generateForm.waitedAmount = waitedAmount < 0 ? 0 : waitedAmount
      return this.generateForm.waitedAmount
    },

    async handleMqDetail(vendorId) {
      try {
        this.loading = true
        this.multipleSelection = []
        const vendorName = this.vendorOptions.find(
          (v) => v.vendorId === vendorId
        )?.vendorName
        const styleId = this.$route.query.styleId
        const { datas: { remark, styleMoq }} = await styleSearch({ vendorId, styleId })
        Object.assign(this.generateForm, { styleMoq, remark, vendorName })
        const colorNameList = this.tableData.map((v) => v.colorName)
        const { datas } = await colorSearch({ vendorId, styleId, colorNameList })
        if (datas && datas.length) {
          this.tableData.forEach(v => {
            const matchedData = datas.find(vv => vv.colorName === v.colorName)
            if (matchedData) {
              v.colorMoq = matchedData.colorMoq
            }
          })
        } else {
          this.tableData.forEach(v => {
            v.colorMoq = ''
          })
        }
        this.updateMoqTobeAdded(this.tableData, true)
        this.handleMarkColor()
      } finally {
        this.loading = false
      }
    },
    // 校验该款的单款MOQ，所有颜色的单色MOQ是否都有值
    async handleOverBought(multipleSelection, canUpRound) {
      const params = {
        params: JSON.stringify({
          ...pick(this.generateForm, ['vendorName', 'vendorId', 'styleMoq', 'remark', 'waitedAmount']),
          ...pick(this.$route.query, ['styleId', 'styleName', 'purchasePlanCodeList']) }),
        data: JSON.stringify(this.tableData),
        multipleSelection: JSON.stringify(multipleSelection)
      }
      this.$store.dispatch('pages/setOverboughtObject', params)
      const currentQuery = Object.assign({}, this.$route.query, { canUpRound })
      this.$router.push({
        path: 'add-overbought',
        append: false,
        query: currentQuery
      })
    },
    goBack() {
      this.$router.push({
        path: '/purchase-plan-management/purchasplan',
        append: false
      })
    },
    updateTotalPlanPurchaseNumber(datas, flag) {
      this.tableData = datas.map(v => {
        let currentDemand = {}
        const { netDemandList } = v
        currentDemand = flag && netDemandList?.reduce(
          (acc, { netDemandQty, yearMonthStr }) => {
            acc[yearMonthStr] = netDemandQty
            return acc
          },
          {}
        )
        return {
          ...v,
          ...currentDemand,
          totalPlanPurchaseNumber: NP.plus(
            0,
            v.planPurchaseNumber || 0,
            v.overboughtNumber || 0
          )
        }
      })
    },
    updateMoqTobeAdded(datas, clearFlag) {
      this.tableData = datas.map(v => ({
        ...v,
        overboughtNumber: clearFlag ? '' : (v.overboughtNumber || 0),
        moqTobeAdded: v.totalPlanPurchaseNumber ? Math.max(NP.minus(v.colorMoq || 0, v.totalPlanPurchaseNumber || 0), 0) : '/'
      }))
    },
    async handleMoqConfirm(firstFlag) {
      this.loading = true

      try {
        const { datas: { detailList, skuChannelDetailList, styleName, monthList }} = await moqConfirm({
          purchasePlanCodeList: this.purchasePlanCodeList
        })

        this.monthList = monthList
        this.generateForm.styleName = styleName

        if (firstFlag) {
          this.skuTableData = skuChannelDetailList
          this.$store.commit('pages/SET_OVERBOUGHT_SKU_TABLEDATA', skuChannelDetailList)

          if (detailList?.length && this.vendorOptions.length === 1) {
            this.tableData = detailList
            this.updateTotalPlanPurchaseNumber(detailList, true)

            const vendorId = this.vendorOptions[0].vendorId
            this.generateForm.vendorId = vendorId
            await this.handleMqDetail(vendorId)
          } else {
            this.updateTotalPlanPurchaseNumber(detailList, true)
          }
        } else {
          // Handle overbought page confirmation
          const { params, data } = this.addOverboughtDataObject
          const parsedParams = JSON.parse(params)
          const list = data ? JSON.parse(data) : []

          Object.assign(this.generateForm, parsedParams)

          const overboughtMap = new Map(
            this.moqComfirmTableData.map(vv => (
              [`${vv.skuName}-${vv.colorName}-${vv.stockChannelName}`, vv.overboughtNumber]
            ))
          )

          const newOverboughtSkuTableData = this.OverboughtSkuTableData.map(item => {
            const { skuName, colorName, stockChannelName } = item
            const key = `${skuName}-${colorName}-${stockChannelName}`
            if (overboughtMap.has(key)) {
              return { ...item, overboughtNumber: overboughtMap.get(key) }
            }
            return item
          })

          this.$store.commit('pages/SET_OVERBOUGHT_SKU_TABLEDATA', newOverboughtSkuTableData)

          const groupedData = this.sortAndGroupByColorNameAndStockChannel(newOverboughtSkuTableData)

          this.tableData = this.calculateTableData(list, groupedData)
          this.updateTotalPlanPurchaseNumber(this.tableData, false)
          this.updateMoqTobeAdded(this.tableData, false)
        }
        this.handleMarkColor()
      } catch (error) {
        console.error('Error handling Moq confirm:', error)
      } finally {
        this.loading = false
      }
    },

    async removeEvent(row, index) {
      const type = await VXETable.modal.confirm(
        '该操作将取消对应颜色的下单数量，无法撤销，是否继续?'
      )
      const $table = this.$refs.xTable
      if (type === 'confirm') {
        $table.remove(row)
        this.tableData.splice(index, 1)
        // 更新缓存数据
        const list = this.OverboughtSkuTableData.filter(v => v.colorName !== row.colorName)
        this.$store.commit('pages/SET_OVERBOUGHT_SKU_TABLEDATA', list)
      }
    },
    sortAndGroupByColorNameAndStockChannel(data) {
      const groupedData = data.reduce((acc, item) => {
        const key = `${item.colorName}/${item.stockChannelName}`
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(item)
        return acc
      }, {})

      const resultList = Object.values(groupedData).map((group) => {
        const {
          colorName,
          cargoType,
          stockChannelName,
          styleName,
          moqTobeAdded,
          colorMoq
        } = group[0] // 取第一个元素的其他字段

        // 计算总的 planPurchaseNumber 和 overboughtNumber
        const { planPurchaseNumber, overboughtNumber, totalNetDemandQty } =
          group.reduce(
            (acc, curr) => {
              acc.planPurchaseNumber += curr.planPurchaseNumber
              acc.overboughtNumber += Number(curr.overboughtNumber) || 0
              acc.totalNetDemandQty += curr.totalNetDemandQty || 0
              return acc
            },
            { planPurchaseNumber: 0, overboughtNumber: 0, totalNetDemandQty: 0 }
          )

        // 累计动态列
        const totaldDynaticMonthList = this.monthList.map((v) => {
          let sum = 0
          group.map((vv) => {
            sum = NP.plus(sum, vv[v] || 0)
          })
          return { [v]: sum }
        })
        const result = totaldDynaticMonthList.reduce((acc, obj) => {
          const key = Object.keys(obj)[0]
          acc[key] = obj[key]
          return acc
        }, {})

        return {
          ...result,
          moqTobeAdded,
          colorMoq,
          totalNetDemandQty,
          planPurchaseNumber,
          overboughtNumber,
          colorName,
          cargoType,
          stockChannelName,
          styleName
        }
      })

      return resultList
    }
  }
}
</script>
<style scoped>
.disabled-input {
  background-color: #f5f7fa;
  border-color: #dfe4ed;
  color: #c0c4cc;
  cursor: not-allowed;
  width: 150px;
  padding: 0 15px;
}
</style>
