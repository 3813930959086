var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vxe-table',{ref:"xTable",staticClass:"mytable-style",attrs:{"border":"","resizable":"","show-overflow":false,"height":"500","loading":_vm.submitLoading,"data":_vm.poDetailList,"edit-rules":_vm.validRules,"edit-config":{trigger: 'click', mode: 'cell'},"expand-config":{ expandRowKeys: [] }}},[_c('vxe-column',{attrs:{"type":"expand","width":"60"},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var row = ref.row;
return [_c('vxe-table',{attrs:{"border":"","resizable":"","show-overflow":"","max-height":"500","data":row.skuDetailList,"row-class-name":_vm.cellClassName,"edit-config":{trigger: 'click', mode: 'cell'},"edit-rules":_vm.skuRules}},[_c('vxe-column',{attrs:{"type":"seq","title":"序号","width":"60"}}),_c('vxe-column',{attrs:{"prop":"remark","title":"提示信息","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.handleRemark(row))+" ")]}}],null,true)}),_c('vxe-column',{attrs:{"field":"colorName","title":"Color","width":"80"}}),_c('vxe-column',{attrs:{"field":"sizeName","title":"Size","width":"80"}}),_c('vxe-column',{attrs:{"field":"skuName","title":"Sku","min-width":"80"}}),_c('vxe-column',{attrs:{"field":"barcodeStockChannelName","title":"条码渠道","width":"120"}}),_c('vxe-column',{attrs:{"field":"fnskuupc","title":"Fnsku/upc","edit-render":{},"min-width":"160"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"filterable":"","placeholder":_vm.$t('page.selectPlaceholder'),"transfer":""},on:{"change":function (e){ return _vm.handleChange(e,row); }},model:{value:(row.fnskuupc),callback:function ($$v) {_vm.$set(row, "fnskuupc", $$v)},expression:"row.fnskuupc"}},_vm._l((row.fnskuUpcList),function(list,index){return _c('vxe-option',{key:index,attrs:{"label":((list.fnsku|| '无') + "/" + (list.upc || '无')),"value":((list.fnsku) + "/" + (list.upc))}})}),1)]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"filterable":"","placeholder":_vm.$t('page.selectPlaceholder'),"transfer":""},on:{"change":function (e){ return _vm.handleChange(e,row); }},model:{value:(row.fnskuupc),callback:function ($$v) {_vm.$set(row, "fnskuupc", $$v)},expression:"row.fnskuupc"}},_vm._l((row.fnskuUpcList),function(list,index){return _c('vxe-option',{key:index,attrs:{"label":((list.fnsku|| '无') + "/" + (list.upc || '无')),"value":((list.fnsku) + "/" + (list.upc))}})}),1)]}}],null,true)}),_c('vxe-column',{attrs:{"field":"cargoType","title":"装箱数","width":"80"}}),_vm._l((row.stockChannelNameList),function(item,index){return _c('vxe-column',{key:index,attrs:{"width":"120","field":item,"title":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row[item]||0)+" ")]}}],null,true)})})],2)]}}])}),_c('vxe-column',{attrs:{"type":"seq","title":"序号","width":"60"}}),_c('vxe-column',{attrs:{"field":"purchaseOrderCode","title":"采购订单号","min-width":"100px"}}),_c('vxe-column',{attrs:{"field":"vendorName","title":"供应商","min-width":"80px"}}),_c('vxe-column',{attrs:{"field":"totalNumber","title":"订单总数量","min-width":"100px"}}),_c('vxe-column',{attrs:{"field":"barcodeStockChannelId","title":"条码渠道","width":"160","edit-render":{}},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":function($event){return _vm.handleChannel(row)}},model:{value:(row.barcodeStockChannelId),callback:function ($$v) {_vm.$set(row, "barcodeStockChannelId", $$v)},expression:"row.barcodeStockChannelId"}},_vm._l((_vm.StockChannelOptions),function(item){return _c('el-option',{key:item.channelId,attrs:{"label":item.channelName,"value":item.channelId}})}),1)]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":function($event){return _vm.handleChannel(row)}},model:{value:(row.barcodeStockChannelId),callback:function ($$v) {_vm.$set(row, "barcodeStockChannelId", $$v)},expression:"row.barcodeStockChannelId"}},_vm._l((_vm.StockChannelOptions),function(item){return _c('el-option',{key:item.channelId,attrs:{"label":item.channelName,"value":item.channelId}})}),1)]}}])}),_c('vxe-column',{attrs:{"field":"expectDeliveryDate","title":"期望交货日期","edit-render":{},"width":"130"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"value-format":"yyyy-MM-dd","type":"date","disabled":""},model:{value:(row.expectDeliveryDate),callback:function ($$v) {_vm.$set(row, "expectDeliveryDate", $$v)},expression:"row.expectDeliveryDate"}})]}}])}),_c('vxe-column',{attrs:{"field":"saleSeasonDict","title":"销售季节","edit-render":{},"min-width":"100px"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"clearable":"","filterable":""},model:{value:(row.saleSeasonDict),callback:function ($$v) {_vm.$set(row, "saleSeasonDict", $$v)},expression:"row.saleSeasonDict"}},_vm._l((_vm._getAllCommodityDict('SCM_SALE_SEASON')),function(item){return _c('el-option',{key:item.val,attrs:{"label":item.label,"value":item.val}})}),1)]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"clearable":"","filterable":""},model:{value:(row.saleSeasonDict),callback:function ($$v) {_vm.$set(row, "saleSeasonDict", $$v)},expression:"row.saleSeasonDict"}},_vm._l((_vm._getAllCommodityDict('SCM_SALE_SEASON')),function(item){return _c('el-option',{key:item.val,attrs:{"label":item.label,"value":item.val}})}),1)]}}])}),_c('vxe-column',{attrs:{"field":"tagDictList","title":"订单标签","edit-render":{},"width":"140px"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"multiple":"","clearable":"","filterable":""},model:{value:(row.tagDictList),callback:function ($$v) {_vm.$set(row, "tagDictList", $$v)},expression:"row.tagDictList"}},_vm._l((_vm._getAllCommodityDict('SCM_PURCHASE_ORDER_TAG')),function(item){return _c('el-option',{key:item.val,attrs:{"label":item.label,"value":item.val}})}),1)]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"multiple":"","clearable":"","filterable":""},model:{value:(row.tagDictList),callback:function ($$v) {_vm.$set(row, "tagDictList", $$v)},expression:"row.tagDictList"}},_vm._l((_vm._getAllCommodityDict('SCM_PURCHASE_ORDER_TAG')),function(item){return _c('el-option',{key:item.val,attrs:{"label":item.label,"value":item.val}})}),1)]}}])}),_c('vxe-column',{attrs:{"field":"remark","title":"备注","edit-render":{},"min-width":"100px"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"text","maxlength":256,"clearable":""},model:{value:(row.remark),callback:function ($$v) {_vm.$set(row, "remark", $$v)},expression:"row.remark"}})]}}])}),_c('vxe-column',{attrs:{"field":"prepareOrderFlag","title":"是否备料订单","edit-render":{},"width":"130"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('el-radio-group',{model:{value:(row.prepareOrderFlag),callback:function ($$v) {_vm.$set(row, "prepareOrderFlag", $$v)},expression:"row.prepareOrderFlag"}},[_c('el-radio',{attrs:{"label":true}},[_vm._v("是")]),_c('el-radio',{attrs:{"label":false}},[_vm._v("否")])],1)]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-radio-group',{model:{value:(row.prepareOrderFlag),callback:function ($$v) {_vm.$set(row, "prepareOrderFlag", $$v)},expression:"row.prepareOrderFlag"}},[_c('el-radio',{attrs:{"label":true}},[_vm._v("是")]),_c('el-radio',{attrs:{"label":false}},[_vm._v("否")])],1)]}}])}),_c('vxe-column',{attrs:{"field":"deliveryMethod","title":"送货方式","edit-render":{},"width":"130"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('Select',{staticStyle:{"width":"100%"},attrs:{"select-options":_vm._getAllCommodityDict('VENDOR_DELIVERY_METHOD'),"configuration":{ key: 'val', label: 'label', value: 'val' },"clearable":""},model:{value:(row.deliveryMethod),callback:function ($$v) {_vm.$set(row, "deliveryMethod", $$v)},expression:"row.deliveryMethod"}})]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('Select',{staticStyle:{"width":"100%"},attrs:{"select-options":_vm._getAllCommodityDict('VENDOR_DELIVERY_METHOD'),"configuration":{ key: 'val', label: 'label', value: 'val' },"clearable":""},model:{value:(row.deliveryMethod),callback:function ($$v) {_vm.$set(row, "deliveryMethod", $$v)},expression:"row.deliveryMethod"}})]}}])}),_c('vxe-column',{attrs:{"field":"receiptPlaceCode","title":"收货地点","edit-render":{},"width":"130"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('Select',{staticStyle:{"width":"100%"},attrs:{"api-key":"getWarehouse","clearable":""},model:{value:(row.receiptPlaceCode),callback:function ($$v) {_vm.$set(row, "receiptPlaceCode", $$v)},expression:"row.receiptPlaceCode"}})]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('Select',{staticStyle:{"width":"100%"},attrs:{"api-key":"getWarehouse","clearable":""},model:{value:(row.receiptPlaceCode),callback:function ($$v) {_vm.$set(row, "receiptPlaceCode", $$v)},expression:"row.receiptPlaceCode"}})]}}])})],1),_c('div',{staticStyle:{"text-align":"center","margin-top":"30px"}},[_c('el-button',{on:{"click":_vm.goBack}},[_vm._v(" 上一步 ")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitLoading},on:{"click":_vm.handleSubmit}},[_vm._v(" 提交")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }