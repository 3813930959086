<template>
  <div>
    <vxe-table
     ref="xTable"
      border
      resizable
      :show-overflow="false"
      height="500"
      :loading="submitLoading"
      :data="poDetailList"
      class="mytable-style"
      :edit-rules="validRules"
      :edit-config="{trigger: 'click', mode: 'cell'}"
      :expand-config="{ expandRowKeys: [] }"
    >
      <vxe-column type="expand" width="60">
        <template #content="{ row }">
          <vxe-table
            border
            resizable
            show-overflow
            max-height="500"
            :data="row.skuDetailList"
            :row-class-name="cellClassName"
            :edit-config="{trigger: 'click', mode: 'cell'}"
            :edit-rules="skuRules"
          >
            <vxe-column type="seq" title="序号" width="60" />
            <vxe-column prop="remark" title="提示信息" width="100">
              <template #default="{ row }">
                {{ handleRemark(row) }}
              </template>
            </vxe-column>
            <vxe-column field="colorName" title="Color" width="80" />
            <vxe-column field="sizeName" title="Size" width="80" />
            <vxe-column field="skuName" title="Sku" min-width="80"/>
            <vxe-column field="barcodeStockChannelName" title="条码渠道" width="120"   />
            <vxe-column field="fnskuupc" title="Fnsku/upc" :edit-render="{}" min-width="160">
              <template #edit="{row}">
                <vxe-select
                  v-model="row.fnskuupc"
                  filterable
                  :placeholder="$t('page.selectPlaceholder')"
                  transfer
                  @change="e=>handleChange(e,row)"
                >
                  <vxe-option
                    v-for="(list,index) in row.fnskuUpcList"
                    :key="index"
                    :label="`${list.fnsku|| '无'}/${list.upc || '无'}`"
                    :value="`${list.fnsku}/${list.upc}`"
                  />
                </vxe-select>
              </template>
              <template #default="{ row }">
                <vxe-select
                  v-model="row.fnskuupc"
                  filterable
                  :placeholder="$t('page.selectPlaceholder')"
                  transfer
                  @change="e=>handleChange(e,row)"
                >
                  <vxe-option
                    v-for="(list,index) in row.fnskuUpcList"
                    :key="index"
                    :label="`${list.fnsku|| '无'}/${list.upc || '无'}`"
                    :value="`${list.fnsku}/${list.upc}`"
                  />
                </vxe-select>
              </template>
            </vxe-column>
            <vxe-column field="cargoType" title="装箱数" width="80" />

            <vxe-column
              v-for="(item, index) in row.stockChannelNameList"
              :key="index"
              width="120"
              :field="item"
              :title="item"
            >
            <template #default="{ row }">
              {{ row[item]||0}}
            </template>
            </vxe-column>


          </vxe-table>
        </template>
      </vxe-column>
      <vxe-column type="seq" title="序号" width="60" />
      <vxe-column field="purchaseOrderCode" title="采购订单号"  min-width="100px"/>
      <vxe-column field="vendorName" title="供应商" min-width="80px"/>
      <vxe-column field="totalNumber" title="订单总数量"  min-width="100px"/>
      <vxe-column field="barcodeStockChannelId" title="条码渠道" width="160" :edit-render="{}">
        <template #edit="{ row }">
          <el-select
            v-model="row.barcodeStockChannelId"
            filterable
            style="width: 100%;"
            @change="handleChannel(row)"
          >
            <el-option
              v-for="item in StockChannelOptions"
              :key="item.channelId"
              :label="item.channelName"
              :value="item.channelId"
            />
          </el-select>
        </template>
        <template #default="{ row }">
          <el-select
            v-model="row.barcodeStockChannelId"
            filterable
           style="width: 100%;"
            @change="handleChannel(row)"
          >
            <el-option
              v-for="item in StockChannelOptions"
              :key="item.channelId"
              :label="item.channelName"
              :value="item.channelId"
            />
          </el-select>
        </template>
      </vxe-column>
      <vxe-column field="expectDeliveryDate" title="期望交货日期" :edit-render="{}" width="130" >
        <template #edit="{ row }">
          <el-date-picker
            v-model="row.expectDeliveryDate"
            value-format="yyyy-MM-dd"
            type="date"
            disabled
            style="width: 100%"
          />
        </template>
      </vxe-column>
      <vxe-column field="saleSeasonDict" title="销售季节" :edit-render="{}" min-width="100px">
        <template #edit="{ row }">
          <el-select v-model="row.saleSeasonDict" style="width: 100%" clearable filterable>
            <el-option v-for="item in _getAllCommodityDict('SCM_SALE_SEASON')" :key="item.val" :label="item.label" :value="item.val" />
          </el-select>
        </template>
        <template #default="{ row }">
          <el-select v-model="row.saleSeasonDict" style="width: 100%" clearable filterable>
            <el-option v-for="item in _getAllCommodityDict('SCM_SALE_SEASON')" :key="item.val" :label="item.label" :value="item.val" />
          </el-select>
        </template>
      </vxe-column>
      <vxe-column field="tagDictList" title="订单标签" :edit-render="{}" width="140px">
        <template #edit="{ row }">
          <el-select v-model="row.tagDictList" multiple style="width: 100%" clearable filterable>
            <el-option v-for="item in _getAllCommodityDict('SCM_PURCHASE_ORDER_TAG')" :key="item.val" :label="item.label" :value="item.val" />
          </el-select>
        </template>
        <template #default="{ row }">
          <el-select v-model="row.tagDictList" multiple style="width: 100%" clearable filterable>
            <el-option v-for="item in _getAllCommodityDict('SCM_PURCHASE_ORDER_TAG')" :key="item.val" :label="item.label" :value="item.val" />
          </el-select>
        </template>
      </vxe-column>
      <vxe-column field="remark" title="备注" :edit-render="{}" min-width="100px">
        <template #edit="{ row }">
          <el-input
            v-model="row.remark"
            type="text"
            :maxlength="256"
            style="width: 100%"
            clearable
          />
        </template>
      </vxe-column>
      <vxe-column field="prepareOrderFlag" title="是否备料订单" :edit-render="{}" width="130" >
        <template #edit="{ row }">
          <el-radio-group v-model="row.prepareOrderFlag">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </template>
        <template #default="{ row }">
          <el-radio-group v-model="row.prepareOrderFlag">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </template>
      </vxe-column>

      <vxe-column field="deliveryMethod" title="送货方式" :edit-render="{}" width="130">
        <template #edit="{ row }">
          <Select
            v-model="row.deliveryMethod"
            style="width: 100%"
            :select-options="_getAllCommodityDict('VENDOR_DELIVERY_METHOD')"
            :configuration="{ key: 'val', label: 'label', value: 'val' }"
            clearable
          />
        </template>
        <template #default="{ row }">
          <Select
            v-model="row.deliveryMethod"
            style="width: 100%"
            :select-options="_getAllCommodityDict('VENDOR_DELIVERY_METHOD')"
            :configuration="{ key: 'val', label: 'label', value: 'val' }"
            clearable
          />
        </template>
      </vxe-column>
      <vxe-column field="receiptPlaceCode" title="收货地点" :edit-render="{}" width="130">
        <template #edit="{ row }">
          <Select
            v-model="row.receiptPlaceCode"
            style="width: 100%"
            api-key="getWarehouse"
            clearable
          />
        </template>
        <template #default="{ row }">
          <Select
            v-model="row.receiptPlaceCode"
            style="width: 100%"
            api-key="getWarehouse"
            clearable
          />
        </template>
      </vxe-column>
    </vxe-table>
    <div style="text-align: center; margin-top: 30px">
      <el-button @click="goBack"> 上一步 </el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleSubmit"> 提交</el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { StockChannelList } from '@/api/scm-api'
import { poConfirmByNoSplitApi, selectSkuBarCodeChannelApi, prToPoApi } from '@/api/purchasebussiness/purchaseplan.js'
export default {
  components: {
    Select
  },
  mixins: [commodityInfoDict],
  props: {
    stepName: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      poDetailList: [],
      StockChannelOptions: [],
      submitLoading: false,
      validRules: {
        barcodeStockChannelId: [
          { required: true, message: '必填', trigger: 'change' }
        ],
        prepareOrderFlag: [
          { required: true, message: '必填', trigger: 'change' }
        ],
        expectDeliveryDate: [
          { required: true, message: '必填', trigger: 'change' }
        ],
        deliveryMethod: [
          { required: true, message: '必填', trigger: 'change' }
        ],
        receiptPlaceCode: [
          { required: true, message: '必填', trigger: 'change' }
        ]
      },
      skuRules:{
        fnskuupc:[
          { required: true, message: '必填', trigger: 'change'}
        ]
      }
    }
  },
  computed: {
    ...mapGetters([ 'overboughtVendorData', 'getSplitDetails', 'splitOrderData'])
  },
  watch: {
    stepName(val) {
      if (val && val === 2) {
        this.getList()
      }
    }
  },
  mounted() {
    this._StockChannelList()
  },
  methods: {
    async getList() {
      // 不拆分
      if (!this.splitOrderData.length) {
        try {
          this.submitLoading = true
          const params = {}
          const { styleName, purchasePlanCodeList } = this.$route.query
          const planDetailList = []
          const skuChannelOverboughtList = []
      this.getSplitDetails?.detailList?.map(item => {
        planDetailList.push(...item.planDetailList)
        skuChannelOverboughtList.push(...item.skuChannelOverboughtList)
      })
      Object.assign(params, this.overboughtVendorData, {
        purchasePlanCodeList: purchasePlanCodeList.split(','),
        styleName, planDetailList, skuChannelOverboughtList })
      const { datas } = await poConfirmByNoSplitApi(params)
      this.handleTableData(datas)
        } finally {
          this.submitLoading = false
        }
      } else {
        // 拆分
        this.handleTableData(this.splitOrderData)
      }
    },
    // 处理获取数据
    handleTableData(datas) {
      this.poDetailList = datas?.map(item => {
        const skuDetailList = item.skuDetailList?.map(v => {
          const result = v.skuChannelPurchaseList.reduce((accumulator, current) => {
            const key = current.stockChannelName
            accumulator[key] = current.purchaseNumber
            return accumulator
          }, {})

          let fnsku = ''
          let upc = ''
          let fnskuupc = ''
          const { fnskuUpcList } = v
          if (fnskuUpcList?.length === 1) {
            const { fnsku: fnSku, upc: Upc } = fnskuUpcList[0]
            fnsku = fnSku
            upc = Upc
            fnskuupc = `${fnsku|| '无'}/${upc || '无'}`
          }
          if (fnskuUpcList?.length > 1) {
            const arr = fnskuUpcList.filter(v => v.fnsku)
            if (arr.length === 1) {
              const { fnsku: fnSkuDefault, upc: upcDefault } = arr[0]
              fnsku = fnSkuDefault
              upc = upcDefault
              fnskuupc = `${fnsku|| '无'}/${upc || '无'}`
            }
          }

          return Object.assign(v, result, { fnskuUpcList, fnsku: fnsku || '', upc: upc || '', fnskuupc,
            tipMsg: '' })
        })
        console.log('skuDetailList: ', skuDetailList)
        return Object.assign(item, { deliveryMethod: item.deliveryMethod + '', skuDetailList })

      })
    },
    async _StockChannelList() {
      const { datas } = await StockChannelList('')
      this.StockChannelOptions = datas
    },
    handleRemark(row) {
      const { fnskuUpcList } = row
      row.remark = fnskuUpcList && !fnskuUpcList?.length?`未找到有效的Fnsku/upc，请核实!`:''
      if (fnskuUpcList && !fnskuUpcList?.length) return `未找到有效的Fnsku/upc，请核实!`
      return ''
    },
    handleChange({ value }, row) {
      const [fnsku, upc] = value.split('/')
      row.fnsku = fnsku === 'null' ? null : fnsku
      row.upc = upc === 'null' ? null : upc
      this.$refs.xTable.updateData()
    },
    async handleChannel(row) {
      const requestDTOList = [row]
      const { datas } = await selectSkuBarCodeChannelApi(requestDTOList)
      datas?.map(item => {
        const skuDetailList = item.skuDetailList?.map(v => {
          const result = v.skuChannelPurchaseList.reduce((accumulator, current) => {
            const key = current.stockChannelName
            accumulator[key] = current.purchaseNumber
            return accumulator
          }, {})

          let fnsku = ''
          let upc = ''
          let fnskuupc = ''
          const { fnskuUpcList } = v
          if (fnskuUpcList?.length === 1) {
            const { fnsku: fnSku, upc: Upc } = fnskuUpcList[0]
            fnsku = fnSku
            upc = Upc
            fnskuupc = `${fnsku|| '无'}/${upc || '无'}`
          }
          if (fnskuUpcList?.length > 1) {
            const arr = fnskuUpcList.filter(v => v.fnsku)
            if (arr.length === 1) {
              const { fnsku: fnSkuDefault, upc: upcDefault } = arr[0]
              fnsku = fnSkuDefault
              upc = upcDefault
              fnskuupc = `${fnsku|| '无'}/${upc || '无'}`
            }
          }

          return Object.assign(v, result, { fnskuUpcList, fnsku: fnsku || '', upc: upc || '', fnskuupc,
            tipMsg: '' })
        })
        return Object.assign(item, { deliveryMethod: item.deliveryMethod + '', skuDetailList })
      })
      Object.assign(row, datas?.[0])
      this.$refs.xTable.updateData()
    },
    goBack() {
      this.$confirm('返回上一步将丢失当前所编辑数据，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$emit('updateActiveName', 1)
          this.$store.commit('pages/SET_OVERBOUGHT_SPLIT_ORDER_DATA', [])
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    async handleSubmit() {
      const errMap = await this.$refs.xTable.validate(true).catch(errMap => errMap)
      if (errMap) return this.$message.error('请检查必填项')


      const errMap1 = this.poDetailList.filter(item => item.skuDetailList.some(i => !i.fnskuupc))
      this.$refs.xTable.setRowExpand(errMap1,true)
      if (errMap1.length) return this.$message.error('请检查必填项')


      const hasError = this.poDetailList.filter(item => item.skuDetailList.some(i => i.remark))
      this.$refs.xTable.setRowExpand(hasError,true)
      if (hasError.length) return this.$message.error('存在错误信息无法提交')


      try {
        this.submitLoading = true
        const { styleName, purchasePlanCodeList } = this.$route.query
        const params = {}
        this.poDetailList.map(item=>Object.assign(item,{saleSeasonName:item.saleSeasonDict}))
        Object.assign(params, this.overboughtVendorData,
          { poDetailList: this.poDetailList,
            purchasePlanCodeList: purchasePlanCodeList.split(','),
            styleName })
        const errMap = this.poDetailList.some(item => item.skuChannelOverboughtList.length)
        if(errMap){
          this.$confirm('当前已添加超买数量，确认提交将生成超买计划单，并与已选择的PR单合并生成采购订单，是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(async() => {
          await prToPoApi(params)
          this.$message.success('操作成功！')
          this.return()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
        } else {
          await prToPoApi(params)
          this.$message.success('操作成功！')
          this.return()
        }


      } finally {
        this.submitLoading = false
      }
    },
    return() {
      this.$router.push({
        path: '/purchase-plan-management/purchasplan',
        append: false,
        query: {}
      })
    },
    cellClassName({ row, rowIndex }) {
        if (row.remark) {
          return 'row-red'
        }
      return ''
    }
  }
}
</script>
<style scoped lang="scss">
.mytable-style {
  /deep/ .row-red {
    color: red;
  }
}
</style>
