<template>
  <div class="app-container">
    <el-steps :active="activeName" align-center class="mb-3">
      <el-step title="MOQ确认" />
      <el-step title="订单明细确认" />
      <el-step title="采购订单确认" />
    </el-steps>
    <main>
      <section v-show="activeName === 0">
        <el-divider content-position="left">MOQ（最小起订量）达成情况</el-divider>
        <Step1 @updateActiveName="val=>activeName=1" />
      </section>
      <section v-show="activeName === 1">
        <Step2 :step-name="activeName" @updateActiveName="val=>activeName=val" />
      </section>
      <section v-show="activeName === 2">
        <Step3 :step-name="activeName" @updateActiveName="val=>activeName=val" />
      </section>
    </main>

  </div>
</template>
<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'

export default {
  components: { Step1, Step2, Step3 },
  mixins: [commodityInfoDict],
  data() {
    return {
      purchasePlanCodeList: [],
      submitLoading: false,
      activeName: 0,
      rules: {
        resource: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      ruleForm: {
        resource: '3'
      },
      tableData: [],

      stockChanelList: [],
      vendorOptions: [],
      receiptPlaceList: [],
      submitFormLoading: false,
      disabled: false,
      shows: false,
      showRow: false,
      form: {
        waveNumber: ''
      },
      generateForm: {
        prepareOrderFlag: '', // 是否备料订单
        vendorId: '',
        predictDeliveryDate: '', // 期望交货日期
        deliveryMethod: '', // 送货方式id
        receiptPlaceCode: '', // 收货地点Code
        purchaseOrderCode: '',
        inStockChannelIdList: '',
        remark: '',
        tagDictList: []
      },

      tabledatas: [
        {
          purchaseOrderCode: '',
          predictDeliveryDate: '',
          list: []
        }
      ],
      untabledatas: [],
      sidvoList: [],
      tableList: [],
      PlatFormAndSiteData: [],
      allPairs: 0,
      loading: false
    }
  },
  computed: {
  },
  mounted() {
    // 原有代码
    const { purchasePlanCodeList } = this.$route.query
    this.purchasePlanCodeList = purchasePlanCodeList
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>

</style>
