var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"订单明细拆分","visible":_vm.centerDialogVisible,"width":"90%","center":""},on:{"update:visible":function($event){_vm.centerDialogVisible=$event},"closed":_vm.closed}},[_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('vxe-table',{ref:"colorTable",attrs:{"border":"","height":"400","show-footer":"","footer-method":_vm.footerMethod,"filter-config":{showIcon: false},"row-config":{isHover: true},"data":_vm.colorData}},[_c('vxe-colgroup',{attrs:{"title":"Color"}},[_c('vxe-column',{attrs:{"prop":"colorName","filters":_vm.colorOptions,"show-overflow":"title","filter-method":_vm.customStringFilterMethod,"min-width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return _vm._l((column.filters),function(option,index){return _c('vxe-input',{key:index,attrs:{"type":"text","placeholder":"请输入","clearable":""},on:{"change":function($event){return _vm.filterEvent(option)}},model:{value:(option.data),callback:function ($$v) {_vm.$set(option, "data", $$v)},expression:"option.data"}})})}}])})],1),_c('vxe-column',{attrs:{"prop":"planPurchaseNumber","title":"计划采购数量","min-width":"120","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-checkbox',{attrs:{"disabled":_vm.purNumDisabledAll,"indeterminate":_vm.ispurNumIndeterminate},on:{"change":function (val){ return _vm.handleCheckAllChange(val,'purNum'); }},model:{value:(_vm.purNumCheckAll),callback:function ($$v) {_vm.purNumCheckAll=$$v},expression:"purNumCheckAll"}}),_vm._v(" 计划采购数量 ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"disabled":row.purNumDisabled},on:{"change":function($event){return _vm.handleCheckedCitiesChange('purNum')}},model:{value:(row.purNumChecked),callback:function ($$v) {_vm.$set(row, "purNumChecked", $$v)},expression:"row.purNumChecked"}},[_vm._v(" "+_vm._s(row.planPurchaseNumber)+" ")])]}}])}),_c('vxe-column',{attrs:{"prop":"first","title":_vm.headerTitle[0],"min-width":"100","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-checkbox',{attrs:{"disabled":_vm.firstDisabledAll,"indeterminate":_vm.isfirstIndeterminate},on:{"change":function (val){ return _vm.handleCheckAllChange(val,'first'); }},model:{value:(_vm.firstCheckAll),callback:function ($$v) {_vm.firstCheckAll=$$v},expression:"firstCheckAll"}}),_vm._v(" "+_vm._s(_vm.headerTitle.length===2?_vm.headerTitle[0]:'')+" ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"disabled":row.firstDisabled},on:{"change":function($event){return _vm.handleCheckedCitiesChange('first')}},model:{value:(row.firstChecked),callback:function ($$v) {_vm.$set(row, "firstChecked", $$v)},expression:"row.firstChecked"}},[_vm._v(" "+_vm._s(row.first)+" ")])]}}])}),_c('vxe-column',{attrs:{"prop":"second","title":_vm.headerTitle[1],"width":"120","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-checkbox',{attrs:{"disabled":_vm.secondDisabledAll,"indeterminate":_vm.issecondIndeterminate},on:{"change":function (val){ return _vm.handleCheckAllChange(val,'second'); }},model:{value:(_vm.secondCheckAll),callback:function ($$v) {_vm.secondCheckAll=$$v},expression:"secondCheckAll"}}),_vm._v(" "+_vm._s(_vm.headerTitle.length===2?_vm.headerTitle[1]:'')+" ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"disabled":row.secondDisabled},on:{"change":function($event){return _vm.handleCheckedCitiesChange('second')}},model:{value:(row.secondChecked),callback:function ($$v) {_vm.$set(row, "secondChecked", $$v)},expression:"row.secondChecked"}},[_vm._v(" "+_vm._s(row.second)+" ")])]}}])})],1)],1),_c('el-col',{attrs:{"span":4}},[_c('div',{staticStyle:{"height":"400px","display":"flex","justify-content":"center","align-items":"center"}},[_c('el-button',{attrs:{"icon":"el-icon-arrow-right","type":"primary"},on:{"click":_vm.handleDetails}},[_vm._v(" 分配订单明细 ")])],1)]),_c('el-col',{attrs:{"span":10}},[_c('vxe-table',{ref:"poTable",attrs:{"border":"","resizable":"","height":"400","data":_vm.poData,"edit-config":{trigger: 'click', mode: 'cell'},"edit-rules":_vm.validRules,"footer-method":_vm.footerMethod,"row-config":{isHover: true},"show-footer":""}},[_c('vxe-column',{attrs:{"field":"orderIndex","title":"订单序号","min-width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var rowIndex = ref.rowIndex;
return [_vm._v("PO"+_vm._s(rowIndex +1))]}}])}),_c('vxe-column',{attrs:{"field":"color","title":"颜色","width":"100","align":"center","show-overflow":"title"}}),_c('vxe-column',{attrs:{"field":"poDetails","title":"订单明细","width":"100","align":"center","show-overflow":"title"}}),_c('vxe-column',{attrs:{"prop":"totalNum","title":"总数量","width":"80","align":"center"}}),_c('vxe-column',{attrs:{"field":"expectDeliveryDate","title":"期望交货日期","width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","placeholder":"选择日期","value-format":"yyyy-MM-dd"},model:{value:(row.expectDeliveryDate),callback:function ($$v) {_vm.$set(row, "expectDeliveryDate", $$v)},expression:"row.expectDeliveryDate"}})]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","placeholder":"选择日期","value-format":"yyyy-MM-dd"},model:{value:(row.expectDeliveryDate),callback:function ($$v) {_vm.$set(row, "expectDeliveryDate", $$v)},expression:"row.expectDeliveryDate"}})]}}])}),_c('vxe-column',{attrs:{"title":"操作","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-button',{attrs:{"mode":"text"},on:{"click":function($event){return _vm.removeEvent(row)}}},[_vm._v("删除")])]}}])})],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closed}},[_vm._v("返回")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitLoading},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v("提交")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }