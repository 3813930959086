<template>
  <el-dialog
    title="订单明细拆分"
    :visible.sync="centerDialogVisible"
    width="90%"
    center
    @closed="closed"
  >
    <el-row>
      <el-col :span="10">
        <vxe-table
          ref="colorTable"
          border
          height="400"
          show-footer
          :footer-method="footerMethod"
          :filter-config="{showIcon: false}"
          :row-config="{isHover: true}"
          :data="colorData"
        >
          <vxe-colgroup title="Color">
            <vxe-column prop="colorName" :filters="colorOptions" show-overflow="title" :filter-method="customStringFilterMethod" min-width="100">
              <template #header="{ column }">
                <vxe-input v-for="(option, index) in column.filters" :key="index" v-model="option.data" type="text" placeholder="请输入" clearable @change="filterEvent(option)" />
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-column prop="planPurchaseNumber" title="计划采购数量" min-width="120" align="center">
            <template #header>
              <el-checkbox v-model="purNumCheckAll" :disabled="purNumDisabledAll" :indeterminate="ispurNumIndeterminate" @change="(val)=>handleCheckAllChange(val,'purNum')" />
              计划采购数量
            </template>
            <template #default="{row}">
              <el-checkbox v-model="row.purNumChecked" :disabled="row.purNumDisabled" @change="handleCheckedCitiesChange('purNum')">
                {{ row.planPurchaseNumber }}
              </el-checkbox>
            </template>
          </vxe-column>
          <vxe-column prop="first" :title="headerTitle[0]" min-width="100" align="center">
            <template #header>
              <el-checkbox v-model="firstCheckAll" :disabled="firstDisabledAll" :indeterminate="isfirstIndeterminate" @change="(val)=>handleCheckAllChange(val,'first')" />
              {{ headerTitle.length===2?headerTitle[0]:'' }}
            </template>
            <template #default="{row}">
              <el-checkbox v-model="row.firstChecked" :disabled="row.firstDisabled" @change="handleCheckedCitiesChange('first')">
                {{ row.first }}
              </el-checkbox>
            </template>
          </vxe-column>
          <vxe-column prop="second" :title="headerTitle[1]" width="120" align="center">
            <template #header>
              <el-checkbox v-model="secondCheckAll" :disabled="secondDisabledAll" :indeterminate="issecondIndeterminate" @change="(val)=>handleCheckAllChange(val,'second')" />
              {{ headerTitle.length===2?headerTitle[1]:'' }}
            </template>
            <template #default="{row}">
              <el-checkbox v-model="row.secondChecked" :disabled="row.secondDisabled" @change="handleCheckedCitiesChange('second')">
                {{ row.second }}
              </el-checkbox>
            </template>
          </vxe-column>
        </vxe-table>
      </el-col>
      <el-col :span="4">
        <div style="height:400px;display:flex;justify-content:center;align-items:center;">
          <el-button icon="el-icon-arrow-right" type="primary" @click="handleDetails">
            分配订单明细
          </el-button>
        </div>
      </el-col>
      <el-col :span="10">
        <vxe-table
          ref="poTable"
          border
          resizable
          height="400"
          :data="poData"
          :edit-config="{trigger: 'click', mode: 'cell'}"
          :edit-rules="validRules"
          :footer-method="footerMethod"
          :row-config="{isHover: true}"
          show-footer
        >
          <vxe-column field="orderIndex" title="订单序号" min-width="90" align="center">
            <template #default="{rowIndex}">PO{{ rowIndex +1 }}</template>
          </vxe-column>
          <vxe-column field="color" title="颜色" width="100" align="center" show-overflow="title" />
          <vxe-column field="poDetails" title="订单明细" width="100" align="center" show-overflow="title" />
          <vxe-column prop="totalNum" title="总数量" width="80" align="center" />
          <vxe-column field="expectDeliveryDate" title="期望交货日期" width="150" align="center">
            <template #default="{row}">
              <el-date-picker
                v-model="row.expectDeliveryDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                style="width:100%"
              /></template>
            <template #edit="{row}">
              <el-date-picker
                v-model="row.expectDeliveryDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                style="width:100%"
              /></template>
          </vxe-column>
          <vxe-column title="操作" width="100" align="center">
            <template #default="{ row }">
              <vxe-button mode="text" @click="removeEvent(row)">删除</vxe-button>
            </template>
          </vxe-column>
        </vxe-table>
      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closed">返回</el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleSubmit()">提交</el-button>
    </span>
  </el-dialog>
</template>
<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { poDetailSplit, poConfirmBySplitApi } from '@/api/purchasebussiness/purchaseplan.js'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    headerTitle: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      submitLoading: false,
      colorData: [],
      colorOptions: [
        { data: '' }
      ],
      poData: [],
      validRules: {
        expectDeliveryDate: [
          { required: true, message: '必填', trigger: 'change' }
        ]
      },
      purNumCheckAll: false,
      ispurNumIndeterminate: false,
      firstCheckAll: false,
      isfirstIndeterminate: false,
      secondCheckAll: false,
      issecondIndeterminate: false
    }
  },

  computed: {
    centerDialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    purNumDisabledAll() {
      return this.colorData.some(item => item.purNumChecked)
    },
    firstDisabledAll() {
      return this.colorData.some(item => item.firstChecked)
    },
    secondDisabledAll() {
      return this.colorData.some(item => item.secondChecked)
    },

    ...mapGetters(['getSplitDetails', 'overboughtVendorData'])
  },
  watch: {
    centerDialogVisible(val) {
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    async getList() {
      this.purNumCheckAll = false
      this.ispurNumIndeterminate = false
      this.firstCheckAll = false
      this.isfirstIndeterminate = false
      this.secondCheckAll = false
      this.issecondIndeterminate = false

      this.poData = []
      const { datas } = await poDetailSplit(this.getSplitDetails)
      this.colorData = datas.map(item => {
        const earlyYM = this.headerTitle?.[0]
        // 处理预计交货周期的数量
        const first = []
        const second = []
        // 按照表头列表分割数据
        item.overboughtList.forEach(i => {
          if (i.yearMonthStr <= earlyYM) {
            first.push(i)
          } else {
            second.push(i)
          }
        })
        const sumAfter = second.reduce((sum, e) => sum + e.overboughtNumber, 0)

        return Object.assign(item, { second: sumAfter ?? 0, first: first?.[0]?.overboughtNumber ?? 0 },
          { purNumChecked: !item.planPurchaseNumber, firstChecked: !first?.[0]?.overboughtNumber, secondChecked: !sumAfter,
            purNumDisabled: !item.planPurchaseNumber, firstDisabled: !first?.[0]?.overboughtNumber, secondDisabled: !sumAfter })
      }).filter(v => v.second + v.first + v.planPurchaseNumber > 0)
    },
    closed() {
      this.poData = []
      this.colorData = []
      this.centerDialogVisible = false
    },
    customStringFilterMethod({ option, row, column }) {
      if (option.data) {
        // return row[column.prop].indexOf(option.data) > -1
        return String(row[column.prop]).toLowerCase().indexOf(option.data.toLowerCase()) > -1
      }
      return true
    },
    filterEvent(option) {
      const xTable = this.$refs.colorTable
      // 设置为选中状态
      option.checked = true
      // 修改条件之后，需要手动调用 updateData 处理表格数据
      xTable.updateData()
    },
    handleCheckAllChange(val, type) {
      const field = type + 'Checked'
      const colorShowData = this.$refs.colorTable.getTableData().tableData
      this.colorData.filter(item => colorShowData.filter(vv => {
        if (item.colorName === vv.colorName) {
          item[field] = val
        }
      }))

      if (type === 'purNum') {
        this.ispurNumIndeterminate = false
      }
      if (type === 'first') {
        this.isfirstIndeterminate = false
      }
      if (type === 'second') {
        this.issecondIndeterminate = false
      }
      this.$refs.colorTable.updateData()
    },
    handleCheckedCitiesChange(type) {
      if (type === 'purNum') {
        const checkedCount = this.colorData.filter(item => item.purNumChecked).length
        this.purNumCheckAll = checkedCount === this.colorData.length
        this.ispurNumIndeterminate = checkedCount > 0 && checkedCount < this.colorData.length
      }
      if (type === 'first') {
        const checkedCount = this.colorData.filter(item => item.firstChecked).length
        this.firstCheckAll = checkedCount === this.colorData.length
        this.isfirstIndeterminate = checkedCount > 0 && checkedCount < this.colorData.length
      }
      if (type === 'second') {
        const checkedCount = this.colorData.filter(item => item.secondChecked).length
        this.secondCheckAll = checkedCount === this.colorData.length
        this.issecondIndeterminate = checkedCount > 0 && checkedCount < this.colorData.length
      }

      this.$refs.colorTable.updateData()
    },
    sumNum(list, prop) {
      let count = 0
      list.forEach(item => {
        count += Number(item[prop])
      })
      return count
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if (['planPurchaseNumber', 'first', 'second', 'totalNum'].includes(column.prop)) {
            return this.sumNum(data, column.prop)
          }
          return null
        })
      ]
    },
    handleDetails() {
      const ispurNumChecked = this.colorData.filter(item => item.purNumChecked && !item.purNumDisabled)
      const isfirstChecked = this.colorData.filter(item => item.firstChecked && !item.firstDisabled)
      const issecondChecked = this.colorData.filter(item => item.secondChecked && !item.secondDisabled)
      const isChecked = [...ispurNumChecked, ...isfirstChecked, ...issecondChecked]

      if (!isChecked.length) return this.$message.warning('至少选中一个颜色的采购明细')
      if (this.poData.length + 1 > 3) return this.$message.warning('右侧PO单不得大于3个')

      // 计划，超买信息
      const planDetailList = []
      const skuChannelOverboughtList = []
      this.colorData?.map(item => {
        if (item.purNumChecked && !item.purNumDisabled) {
          planDetailList.push(...item.planDetailList)
          skuChannelOverboughtList.push(...item.planPurchaseOverboughtList)
        }
        if (item.firstChecked && !item.firstDisabled) {
          const arr = item.overboughtList?.filter(e => e.yearMonthStr === this.headerTitle[0])
          if (Array.isArray(arr)) {
            arr.map(ee => {
              skuChannelOverboughtList.push(...ee.skuChannelOverboughtList)
            })
          }
        }
        if (item.secondChecked && !item.secondDisabled) {
          const arr = item.overboughtList?.filter(e => e.yearMonthStr !== this.headerTitle[0])
          if (Array.isArray(arr)) {
            arr.map(ee => {
              skuChannelOverboughtList.push(...ee.skuChannelOverboughtList)
            })
          }
        }
      })
      // 禁用
      this.colorData.map(item => {
        if (item.purNumChecked) {
          item.purNumDisabled = item.purNumChecked
        }
        if (item.firstChecked) {
          item.firstDisabled = item.firstChecked
        }
        if (item.secondChecked) {
          item.secondDisabled = item.secondChecked
        }
      })

      const firstSum = isfirstChecked.reduce((sum, e) => sum + e.first, 0)
      const secondSum = issecondChecked.reduce((sum, e) => sum + e.second, 0)
      const purNumSum = ispurNumChecked.reduce((sum, e) => sum + e.planPurchaseNumber, 0)
      const totalNum = firstSum + secondSum + purNumSum
      const color = [...new Set(isChecked?.map(i => i.colorName))]
      const title1 = ispurNumChecked.length > 0 ? '计划采购数量,' : ''
      const title2 = isfirstChecked.length > 0 ? this.headerTitle[0] ?? '' : ''
      const title3 = issecondChecked.length > 0 ? this.headerTitle[1] ?? '' : ''
      const poDetails = title1 + title2 + title3

      const planArr = ispurNumChecked?.map(item => item.planPurchaseExpectDeliveryDate)
      planArr.sort()
      const minPlan = planArr.length ? dayjs(planArr[0]).format('YYYY-MM-DD') : null

      const isfirstCheckedArr = isfirstChecked.map(first => first.overboughtList?.[0].expectDeliveryDate)
      const issecondCheckedArr = issecondChecked.map(first => first.overboughtList?.[1].expectDeliveryDate)
      const overArr = [...isfirstCheckedArr, ...issecondCheckedArr]
      overArr.sort()
      const minOver = overArr.length ? dayjs(overArr[0]).format('YYYY-MM-DD') : null

      const expectDeliveryDate = minPlan && !minOver ? minPlan : minOver && !minPlan ? minOver
        : [minPlan, minOver].sort()[0]

      const records = {
        color,
        poDetails,
        totalNum,
        planDetailList,
        skuChannelOverboughtList,
        expectDeliveryDate,
        isfirstChecked,
        issecondChecked,
        ispurNumChecked
      }
      this.poData.unshift(records)
      // 更新数据
      this.$refs.poTable.updateData()
      this.$refs.colorTable.updateData()
    },
    removeEvent(row) {
      const xTable = this.$refs.poTable
      this.$confirm('该操作将删除当前所分配订单明细，无法撤销，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        xTable.remove(row)
        this.poData = xTable.getTableData().tableData
        this.$refs.poTable.updateData()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })

        this.colorData.map(item => {
          const { color, ispurNumChecked, isfirstChecked, issecondChecked } = row
          console.log('color: ', color)
          const ispurNumFlag = color.includes(item.colorName) && ispurNumChecked?.map(e => e.colorName).includes(item.colorName)
          console.log('ispurNumFlag: ', ispurNumChecked, ispurNumFlag)
          if (ispurNumFlag) {
            item.purNumDisabled = false
            item.purNumChecked = false
          }
          const isfirstFlag = color.includes(item.colorName) && isfirstChecked?.map(e => e.colorName).includes(item.colorName)
          console.log('isfirstFlag: ', isfirstChecked, isfirstFlag)
          if (isfirstFlag) {
            item.firstDisabled = false
            item.firstChecked = false
          }
          const issecondFlag = color.includes(item.colorName) && issecondChecked?.map(e => e.colorName).includes(item.colorName)
          console.log('issecondFlag: ', issecondChecked, issecondFlag)
          if (issecondFlag) {
            item.secondDisabled = false
            item.secondChecked = false
          }
        })

        this.purNumCheckAll = this.colorData.filter(item => item.purNumDisabled).length === this.colorData.length

        this.ispurNumIndeterminate = false

        this.firstCheckAll = this.colorData.filter(item => item.firstDisabled).length === this.colorData.length

        this.isfirstIndeterminate = false

        this.secondCheckAll = this.colorData.filter(item => item.secondDisabled).length === this.colorData.length

        this.issecondIndeterminate = false

        this.$refs.colorTable.updateData()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async handleSubmit() {
      const isAllChecked = this.colorData.filter(item => !item.purNumDisabled || !item.firstDisabled || !item.secondDisabled)
      if (isAllChecked.length) return this.$message.error('所有颜色、计划采购数量、超买明细都须被分配至采购订单中')
      const $table = this.$refs.poTable
      const errMap = await $table.validate()
      if (errMap) return this.$message.error('期望交货日期必填')
      const isFull = this.poData.filter(item => !item.expectDeliveryDate)
      if (isFull.length) return this.$message.error('期望交货日期必填')

      // 处理订单数，
      try {
        this.submitLoading = true
        const params = {}
        const { styleName, purchasePlanCodeList } = this.$route.query
        const poList = this.poData?.map(item => {
          const { expectDeliveryDate, planDetailList, skuChannelOverboughtList } = item
          return Object.assign({}, { expectDeliveryDate, planDetailList, skuChannelOverboughtList })
        })
        Object.assign(params, this.overboughtVendorData, {
          purchasePlanCodeList: purchasePlanCodeList.split(','),
          styleName, poList
        })

        const { datas } = await poConfirmBySplitApi(params)
        this.$store.commit('pages/SET_OVERBOUGHT_SPLIT_ORDER_DATA', datas)
        this.$emit('goNext')
        this.centerDialogVisible = false
      } finally {
        this.submitLoading = false
      }
    }
  }
}
</script>
