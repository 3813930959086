import request from '@/utils/scm-request'

// moq确认
export function moqConfirm(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/moqConfirm`,
    method: 'post',
    data
  })
}

// 超买凑单color
export function overboughtColor(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/overboughtColor`,
    method: 'post',
    data,
    timeout: 9999999

  })
}

// 超买凑单导出
export function overboughtExport(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/overboughtExport`,
    method: 'post',
    data,
    responseType: 'arraybuffer'
  })
}

// 超买凑单导入
export function overboughtImport(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/overboughtImport`,
    method: 'post',
    data
  })
}

// 超买凑单sku
export function overboughtSku(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/overboughtSku`,
    method: 'post',
    data,
    timeout: 999999
  })
}

// 超买凑单建议
export function overboughtSuggest(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/overboughtSuggest`,
    method: 'post',
    data
  })
}

// 订单明细确认color
export function poDetailConfirmColor(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/poDetailConfirmColor`,
    method: 'post',
    data,
    timeout: 9999999
  })
}

// 订单明细确认Sku
export function poDetailConfirmSku(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/poDetailConfirmSku`,
    method: 'post',
    data,
    timeout: 9999999

  })
}

// 订单明细拆分
export function poDetailSplit(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/poDetailSplit`,
    method: 'post',
    data
  })
}

export function colorSearch(data) {
  return request({
    url: `/scm-purchase/dsb/moq/colorSearch`,
    method: 'post',
    data
  })
}

export function styleSearch(params) {
  return request({
    url: `/scm-purchase/dsb/moq/styleSearch`,
    method: 'post',
    params
  })
}

// 超买凑单提交校验
export function overboughtSubmitValidate(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/overboughtSubmitValidate`,
    method: 'post',
    data
  })
}

// 采购订单确认（拆分）
export function poConfirmBySplitApi(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/poConfirmBySplit`,
    method: 'post',
    data
  })
}

// 采购订单确认（不拆分）
export function poConfirmByNoSplitApi(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/poConfirmByNoSplit`,
    method: 'post',
    data
  })
}

// 采购订单确认-选择sku条码渠道
export function selectSkuBarCodeChannelApi(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/selectSkuBarCodeChannel`,
    method: 'post',
    data
  })
}

// pr转po
export function prToPoApi(data) {
  return request({
    url: `/scm-purchase/purchasePlan/prToPo/prToPo`,
    method: 'post',
    data
  })
}

// 和style关联的供应商
export function vendorListByStyles(data) {
  return request({
    url: `/scm-purchase/basic/styleBasic/vendorListByStyles`,
    method: 'post',
    data
  })
}
