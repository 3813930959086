<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="按Color查看" name="first" />
      <el-tab-pane label="按Sku查看" name="second" />
    </el-tabs>
    <QueryForm
      v-model="queryForm"
      :query-btn-loading="tableLoading"
      @query="handleQueryForFilter"
      @reset="handleQueryFormReset"
    >
      <el-form-item label="Color" prop="selectColorNameList">
        <el-select v-model="queryForm.selectColorNameList" multiple clearable collapse-tags>
          <el-option v-for="item in colorOptions" :key="item.id" :label="item.colorName" :value="item.colorName" />
        </el-select>
      </el-form-item>
      <el-form-item v-show="activeName==='second'" label="Size" prop="selectSizeNameList">
        <el-select v-model="queryForm.selectSizeNameList" multiple clearable collapse-tags>
          <el-option v-for="item in sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
        </el-select>
      </el-form-item>
      <el-form-item v-show="activeName==='second'" label="SKU" prop="selectSkuNameList">
        <el-input
          v-model="queryForm.selectSkuNameList"
          placeholder="多个以换行符分隔"
          type="textarea"
          style="width: 100%"
          autosize
          resize="none"
        />
      </el-form-item>
      <el-form-item label="备货渠道" prop="selectStockChannelIdList">
        <Select
          v-model="queryForm.selectStockChannelIdList"
          api-key="StockChannelList"
          multiple
          filterable
          collapse-tags
        />
      </el-form-item>
    </QueryForm>
    <el-button type="text" :loading="tableLoading" @click="handleSplitDetails">订单明细拆分</el-button>
    <!-- 缓存全部 -->
    <div v-if="activeName ==='first'" v-loading="tableLoading">
      <div v-for="(item,index) in tableDatas" :key="index">
        <vxe-table
          ref="xTable"
          class="mytable-style"
          border
          :resizable="false"
          :column-config="{ resizable: false }"
          show-footer
          max-height="300"
          :loading="tableLoading"
          :data="item"
          :footer-method="footerMethod"
          :show-header="index===0"
          :cell-class-name="cellClassName"
          align="center"
        >
          <vxe-column prop="colorName" title="Color" width="240" />
          <vxe-column prop="cargoTypeList" title="装箱数" width="240">
            <template #default="{ row }">
              {{ Array.isArray(row.cargoTypeList)&&row.cargoTypeList.length && Math.max(...row.cargoTypeList) }}
            </template>
          </vxe-column>
          <vxe-column prop="stockChannelName" title="备货渠道" width="240" />
          <vxe-column prop="planPurchaseNumber" title="计划采购数量" width="200" />
          <vxe-column prop="overboughtNumber" title="超买数量" width="200" />
          <vxe-colgroup v-if="headerTitle.length" prop="netDemandList" title="预计交货周期" width="450">
            <vxe-column prop="first" :title="headerTitle[0]" width="150" />
            <vxe-column prop="second" :title="headerTitle[1]" width="150" />
            <vxe-column prop="totalNum" title="总计" width="150" />
          </vxe-colgroup>
        </vxe-table>
      </div>
      <vxe-table
        border
        :resizable="false"
        :column-config="{ resizable: false }"
        :loading="tableLoading"
        :data="totalSum"
        :show-header="false"
        align="center"
      >
        <vxe-column prop="colorName" title="Color" width="240">
          <template #default>
            <span>总计</span>
          </template>
        </vxe-column>
        <vxe-column prop="cargoTypeList" title="装箱数" width="240" />
        <vxe-column prop="stockChannelName" title="备货渠道" width="240" />
        <vxe-column prop="planPurchaseNumber" title="计划采购数量" width="200" />
        <vxe-column prop="overboughtNumber" title="超买数量" width="200" />
        <vxe-colgroup v-if="headerTitle.length" prop="netDemandList" title="预计交货周期" width="450">
          <vxe-column prop="first" :title="headerTitle[0]" width="150" />
          <vxe-column prop="second" :title="headerTitle[1]" width="150" />
          <vxe-column prop="totalNum" title="总计" width="150" />
        </vxe-colgroup>
      </vxe-table>
    </div>
    <div v-else>
      <vxe-table
        ref="skuTable"
        class="mytable-style"
        border
        resizable
        show-footer
        :data="skuDatas"
        :footer-method="footerMethod"
        :cell-class-name="cellClassName"
        :loading="tableLoading"
        align="center"
        :max-height="500"
        :scroll-y="{enabled: true}"
      >
        <vxe-column prop="colorName" title="Color" width="120" />
        <vxe-column prop="sizeName" title="Size" />
        <vxe-column prop="skuName" title="SKU" width="200" />
        <vxe-column prop="cargoType" title="装箱数" />

        <vxe-column prop="stockChannelName" title="备货渠道" />
        <vxe-column prop="planPurchaseNumber" title="计划采购数量" />
        <vxe-column prop="overboughtNumber" title="超买数量" />
        <vxe-colgroup v-if="headerTitle.length" prop="netDemandList" title="预计交货周期">
          <vxe-column prop="first" :title="headerTitle[0]" />
          <vxe-column prop="second" :title="headerTitle[1]" />
          <vxe-column prop="totalNum" title="总计" />
        </vxe-colgroup>
      </vxe-table>
    </div>
    <div style="text-align: center; margin-top: 30px">
      <el-button :loading="loading" @click="handleToReview(0)">上一步</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="handleToReview(2)"
      >下一步</el-button>
    </div>
    <SplitDetails v-model="detailDialog" :header-title="headerTitle" @goNext="handleToReview(2)" />
  </div>
</template>
<script>
import NP from 'number-precision'
import { mapGetters } from 'vuex'
import { preText } from '@/utils/index.js'
import QueryForm from '@/components/QueryForm'
import Select from '@/components/Selection'
import SplitDetails from '../components/SplitDetails'
import { querySizeList, queryColorList } from '@/api/scm-api'
import { poDetailConfirmColor, poDetailConfirmSku } from '@/api/purchasebussiness/purchaseplan.js'

export default {
  components: { QueryForm, Select, SplitDetails },
  props: {
    stepName: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      activeName: 'first',
      detailDialog: false,
      loading: false,
      tableLoading: false,
      tableDatas: [],
      skuDatas: [],
      headerTitle: [],
      sizeOptions: [],
      colorOptions: [],
      queryForm: { },
      totalSum: []
    }
  },
  computed: {
    searchSchema() {
      const { styleName, purchasePlanCodeList } = this.$route.query
      const { selectColorNameList = [],
        selectSizeNameList = [],
        selectSkuNameList = '',
        selectStockChannelIdList = [] } = this.queryForm
      const list = this.OverboughtSkuTableData.filter(v => v.overboughtNumber)

      return Object.assign({}, this.overboughtVendorData, {
        purchasePlanCodeList: purchasePlanCodeList.split(','),
        selectColorNameList, selectSizeNameList,
        selectSkuNameList: selectSkuNameList ? preText(selectSkuNameList, true) : [], selectStockChannelIdList,
        skuChannelOverboughtList: list?.map(item => Object.assign(item, { styleName })),
        styleName
      })
    },
    ...mapGetters(['OverboughtSkuTableData', 'overboughtVendorData', 'getSplitDetails'])
  },
  watch: {
    stepName(val) {
      if (val && val === 1) {
        this.handleQueryForFilter()
        // 处理表头标题
        this.headerTitle = this.handleTitle(this.getSplitDetails)
      }
    }
  },
  mounted() {
    this._queryColorOptions()
    this._querySizeOptions()
  },
  methods: {
    async _queryColorOptions() {
      const { datas } = await queryColorList({
        styleId: this.$route.query.styleId
      })
      this.colorOptions = datas
    },
    async _querySizeOptions() {
      const { datas } = await querySizeList({
        styleId: this.$route.query.styleId
      })
      this.sizeOptions = datas
    },
    async handleQueryForFilter() {
      if (this.activeName === 'first') {
        try {
          this.loading = true
          this.tableLoading = true
          const { datas } = await poDetailConfirmColor(this.searchSchema)
          this.getList(datas)
        } finally {
          this.loading = false
          this.tableLoading = false
        }
      } else {
        try {
          this.loading = true
          this.tableLoading = true
          const { datas } = await poDetailConfirmSku(this.searchSchema)
          this.getList(datas)
        } finally {
          this.loading = false
          this.tableLoading = false
        }
      }
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQueryForFilter()
    },
    handleSplitDetails() {
      this.detailDialog = true
    },

    compareMonth(a, b) {
      return a.localeCompare(b, 'en', { numeric: true, sensitivity: 'base' })
    },
    handleTitle(datas) {
      if (!datas) return
      const arr = datas.monthList
      arr.sort(this.compareMonth)
      return [arr[0], arr[1] + '及以后']
    },
    handlerArr(arr, key) {
      const obj = {}
      arr.forEach((item) => {
        const value = item[key]
        // 处理预计交货周期的数量
        const earlyYM = this.headerTitle?.[0]
        const first = []
        const second = []
        // 按照表头列表分割数据
        item.netDemandList.forEach(i => {
          if (i.yearMonthStr <= earlyYM) {
            first.push(i)
          } else {
            second.push(i)
          }
        })
        const sumAfter = second.reduce((sum, e) => sum + e.netDemandQty, 0)
        const totalNum = NP.plus(Number(sumAfter ?? 0), Number(first?.[0]?.netDemandQty ?? 0))
        Object.assign(item, { second: sumAfter ?? 0, first: first?.[0]?.netDemandQty ?? 0, totalNum })

        obj[value] ? obj[value].push(item) : (obj[value] = [item])
      })
      return Object.keys(obj).map((item) => obj[item])
    },
    getList(datas) {
      // // 处理表头标题
      // this.headerTitle = this.handleTitle(datas)
      if (this.activeName === 'first') {
        const colorArr = this.handlerArr(datas.detailList, 'colorName')
        let planPurchaseNumber = 0
        let overboughtNumber = 0
        let first = 0
        let second = 0
        let totalNum = 0
        colorArr.map(item => {
          item?.map(v => {
            planPurchaseNumber = planPurchaseNumber + v.planPurchaseNumber
            overboughtNumber = overboughtNumber + v.overboughtNumber
            first = first + v.first
            second = second + v.second
            totalNum = totalNum + v.totalNum
          })
        })
        this.totalSum = [{ planPurchaseNumber, overboughtNumber, first, second, totalNum }]
        this.tableDatas = colorArr
      } else {
        this.skuDatas = datas.detailList.map(item => {
          const earlyYM = this.headerTitle?.[0]
          const first = []
          const second = []
          if (!item.netDemandList.length) return Object.assign(item, { second: 0, first: 0, totalNum: 0 })
          item.netDemandList.forEach(i => {
            if (i.yearMonthStr <= earlyYM) {
              first.push(i)
            } else {
              second.push(i)
            }
          })
          const sumAfter = second.reduce((sum, e) => sum + e.netDemandQty, 0)
          const totalNum = NP.plus(Number(sumAfter ?? 0), Number(first?.[0]?.netDemandQty ?? 0))
          return Object.assign(item, { second: sumAfter ?? 0, first: first?.[0]?.netDemandQty ?? 0, totalNum })
        })
      }
    },

    handleClick() {
      this.handleQueryFormReset()
    },
    sumNum(list, field) {
      let count = 0
      list.forEach(item => {
        count += Number(item[field])
      })
      return count
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if (['overboughtNumber', 'planPurchaseNumber', 'first', 'second', 'totalNum'].includes(column.prop)) {
            return this.sumNum(data, column.prop)
          }
          return null
        })
      ]
    },

    handleToReview(step) {
      this.queryForm = this.$options.data().queryForm
      this.$emit('updateActiveName', step)
    },
    cellClassName({ row, column }) {
      if (column.prop === 'first') {
        if (row.first && row.overboughtNumber) {
          return 'row-green'
        }
      }
      if (column.prop === 'second') {
        if (row.first < row.overboughtNumber && row.second) {
          return 'row-green'
        }
      }
      return null
    }

  }
}
</script>
<style scoped lang="scss">
.mytable-style {
  /deep/ .row-green {
    background-color: #95F204;
    color: #000000;
  }
}
</style>
