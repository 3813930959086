var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":_vm.isAuto?'自动凑单':'指定数量凑单',"visible":_vm.visible,"width":"1000px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.close}},[_c('el-form',{ref:"formRef",attrs:{"model":_vm.form}},[(!_vm.isAuto)?_c('div',[_vm._v(" 凑单数量确认： "),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"mb-4",attrs:{"max-height":"400px","border":"","data":_vm.form.colorCustomNumberList}},[_c('el-table-column',{attrs:{"prop":"colorName","label":"凑单Color"}}),_c('el-table-column',{attrs:{"prop":"customNumber","label":"凑单数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{attrs:{"prop":'colorCustomNumberList.' + $index + '.customNumber',"rules":[
                  {
                    required: true,
                    message: _vm.$t('page.required'),
                    trigger: 'blur',
                  } ]}},[_c('el-input-number',{staticStyle:{"width":"100%"},attrs:{"precision":0,"controls":false,"min":1,"max":999999,"clearable":""},model:{value:(row.customNumber),callback:function ($$v) {_vm.$set(row, "customNumber", $$v)},expression:"row.customNumber"}})],1)]}}],null,false,2570608616)})],1)],1):_vm._e(),_vm._v(" 备货渠道取整箱方式: "),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"max-height":"400px","border":"","data":_vm.form.stockChannelFullBoxMethodList}},[_c('el-table-column',{attrs:{"prop":"stockChannelName","label":"备货渠道"}}),_c('el-table-column',{attrs:{"label":"取整箱方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('el-radio-group',{model:{value:(row.fullBoxMethod),callback:function ($$v) {_vm.$set(row, "fullBoxMethod", $$v)},expression:"row.fullBoxMethod"}},_vm._l((_vm.ContainerMethodList),function(item,index){return _c('el-radio',{key:index,attrs:{"label":item.value}},[_vm._v(_vm._s(item.label))])}),1)]}}])})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.$emit('input',false)}}},[_vm._v("返回")]),_c('el-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }