<template>
  <div>
    <el-dialog
      :title="isAuto?'自动凑单':'指定数量凑单'"
      :visible.sync="visible"
      width="1000px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form ref="formRef" :model="form">
        <div v-if="!isAuto">
          凑单数量确认：
          <el-table
            v-loading="loading"
            max-height="400px"
            border
            class="mb-4"
            :data="form.colorCustomNumberList"
          >
            <el-table-column prop="colorName" label="凑单Color" />
            <el-table-column
              prop="customNumber"
              label="凑单数量"
            >
              <template #default="{ row, $index }">
                <el-form-item
                  :prop="'colorCustomNumberList.' + $index + '.customNumber'"
                  :rules="[
                    {
                      required: true,
                      message: $t('page.required'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input-number
                    v-model="row.customNumber"
                    style="width: 100%"
                    :precision="0"
                    :controls="false"
                    :min="1"
                    :max="999999"
                    clearable
                  />
                </el-form-item>
              </template>
            </el-table-column>

          </el-table>
        </div>
        备货渠道取整箱方式:
        <el-table
          max-height="400px"
          border
          style="width: 100%"
          :data="form.stockChannelFullBoxMethodList"
        >
          <el-table-column prop="stockChannelName" label="备货渠道" />
          <el-table-column label="取整箱方式">
            <template #default="{ row }">
              <el-radio-group
                v-model="row.fullBoxMethod"
              >
                <el-radio
                  v-for="(item, index) in ContainerMethodList"
                  :key="index"
                  :label="item.value"
                >{{ item.label }}</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>

        </el-table>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">返回</el-button>
        <el-button :loading="loading" type="primary" @click="handleSubmit">提交</el-button>

      </span>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { omit } from 'lodash'
import { overboughtSuggest } from '@/api/purchasebussiness/purchaseplan.js'
export default {

  props: {
    value: {
      type: Boolean,
      default: false
    },
    detailList: {
      type: Array,
      default: () => []
    },
    monthList: {
      type: Array,
      default: () => []
    },
    colorOptions: {
      type: Array,
      default: () => []
    },
    basicInfo: {
      type: Object,
      default: () => {}
    },
    dialogType: {
      type: String,
      default: ''
    }

  },
  data() {
    return {
      detaiSkuList: [],
      loading: false,
      ContainerMethodList: [{ label: '向上取整箱', value: 'UP' }, { label: '向下取整箱', value: 'DOWN' }, { label: '四舍五入取整', value: 'MROUND' }],
      form: { stockChannelFullBoxMethodList: [], colorCustomNumberList: [] }
    }
  },

  computed: {
    isAuto() {
      return this.dialogType === 'AUTO'
    },
    ...mapGetters(['OverboughtSkuTableData']),
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    submitParams() {
      console.time()
      const { purchasePlanCodeList } = this.basicInfo
      // 更新colorMoq
      const detailList = this.detaiSkuList.map(v => {
        return { ...v, overboughtNumber: v.overboughtNumber || 0, colorMoq: this.detailList.find(vv => vv.colorName === v.colorName)?.colorMoq }
      })
      console.timeEnd()

      return Object.assign({}, this.form, this.basicInfo, { detailList, monthList: this.monthList, purchasePlanCodeList })
    },
    autoParams() {
      return omit(this.submitParams, 'colorCustomNumberList')
    }

  },
  watch: {
    'visible'(val) {
      if (val) {
        this.form.colorCustomNumberList = this.detailList.map(v => ({
          colorName: v.colorName,
          customNumber: undefined
        }))

        const detailColorNames = new Set(this.detailList.map(vv => vv.colorName))
        this.detaiSkuList = this.OverboughtSkuTableData?.filter(v => detailColorNames.has(v.colorName))
        if (this.detaiSkuList.length) {
          const stockChannelFullBoxMethodList = this.detaiSkuList.map(v => {
            const fullBoxMethod = v.stockChannelId === 'Channel-003' ? 'UP' : 'MROUND'
            return { stockChannelId: v.stockChannelId, stockChannelName: v.stockChannelName, fullBoxMethod }
          })

          const myseta = (ary) => {
            const strings = ary?.map((item) => JSON.stringify(item))
            return Array.from(new Set(strings))?.map((item) => JSON.parse(item))
          }
          this.form.stockChannelFullBoxMethodList = myseta(stockChannelFullBoxMethodList)
        }
      }
    }

  },
  created() {

  },
  mounted() {
  },
  methods: {
    close() {
      this.$refs.formRef.clearValidate()
      this.$refs.formRef.resetFields()
    },

    async handleSubmit() {
      this.$refs.formRef.validate(async(valid, obj) => {
        if (valid) {
          try {
            this.loading = true
            const { datas = [] } = await overboughtSuggest(this.isAuto ? this.autoParams : this.submitParams)
            // 存储当前勾选color的sku维度，跳转到超买凑单界面
            this.$store.commit(
              'pages/SET_CURRENT_OVERBOUGHT_SKU_TABLEDATA',
              datas
            )
            this.$emit('close', false)
            this.$emit('success')
          } finally {
            this.loading = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
